.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

img {
  vertical-align: middle;
  width: 23rem;
  height: 25rem;
  border-radius: 50%;
  border: solid;
  margin-bottom: 1rem;
}

#linkedIn {
  margin-right: .25rem;
}

#github {
  margin-left: .25rem;
}
